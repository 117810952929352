import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class RVInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="RV Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "RV insurance is specifically designed to cover the normal and unique risks of owning an RV. Contact us, your Baton Rouge, Louisiana based insurance agency, for a quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="RV on a roadtrip" />
        <Callout
          title="RV Insurance"
          content="Whether you call your RV home or it is used for vacation getaways, you need more than auto insurance to cover your risks in Louisiana. RV insurance is specifically designed to cover the normal and unique risks of owning an RV."
        />
        <div className="site-body page-content">
          <h2>What RV Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Bodily Injury.</strong> Covers you for bodily injury or death from an accident for which you’re at fault and often covers associated legal defense expenses.</li>
          <li><strong>Property Damage.</strong> Protects you if your RV accidentally damages another person or business’s property.</li>
          <li><strong>Medical Payments.</strong> Whether you are at fault or not, this usually pays medical expenses of the driver and passengers in the RV that are in an accident.</li>
          <li><strong>Uninsured Motorists.</strong> This pays for injuries and sometimes certain property damage when involved in an accident with a driver who is uninsured. This will also usually cover hit-and-run accidents.</li>
          <li><strong>Comprehensive.</strong> This covers RV damage resulting from flood, fire, vandalism, theft and other covered perils.</li>
          <li><strong>Collision.</strong> Pays for the damage to a RV when it is hit or hits another object.</li>
          <li><strong>Valuables.</strong> Covers items such as camping equipment, portable electronics, clothing, cooking supplies and more.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="The top 5 RV Insurance Claims are: Tire Failures, Fires, Hitting Low Overhangs & Bridges, Failure to Retract Steps & Awnings, and Animal Infestations."
            source="Insurance Information Institute (I.I.I.)"
          />
          <h2>What RV Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Individuals not on the policy that live with you.</strong> Generally RV insurance will only cover individuals on the policy and those who don’t live with you who have permission to use the RV.</li>
          <li><strong>Loan for Totaled RV.</strong> Collision and Comprehensive insurance will cover your RV’s market value minus your deductible. So if your RV is worth less than you owe, you will need to make up the difference. Gap Insurance can be used to mitigate this risk.</li>
          <li><strong>Custom Parts and Equipment.</strong> If your RV is damaged or stolen, RV insurance will not cover the cost of custom add-ons. However, many policies offer an optional policy to cover this.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a RV Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default RVInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "rv-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-26.jpg" }) {
      ...mainBg
    }
  }
`;
